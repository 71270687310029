<template>
  <div class="task">
    <div class="task-side">
      <div class="task-side-adTitle">农事任务</div>
      <div class="statistics">
        <div
          :class="['task-tatistics-item', tatisticsIndex === 2 ? 'active' : '']"
          @click="getTaskListApi(2)"
        >
          <div class="item-le">
            <img
              class="side_icon"
              src="@/assets/image/task/side_icon1.png"
              alt=""
            />
            <span>我负责的</span>
          </div>
          <div class="item-ri">{{ taskStatistics.leader_count }}</div>
        </div>
        <div
          :class="['task-tatistics-item', tatisticsIndex === 1 ? 'active' : '']"
          @click="getTaskListApi(1)"
        >
          <div class="item-le">
            <img
              class="side_icon"
              src="@/assets/image/task/side_icon2.png"
              alt=""
            />
            <span>我创建的</span>
          </div>
          <div class="item-ri">{{ taskStatistics.create_count }}</div>
        </div>
        <div
          :class="['task-tatistics-item', tatisticsIndex === 3 ? 'active' : '']"
          @click="getTaskListApi(3)"
        >
          <div class="item-le">
            <img
              class="side_icon"
              src="@/assets/image/task/side_icon3.png"
              alt=""
            />
            <span>我参与的</span>
          </div>
          <div class="item-ri">{{ taskStatistics.worker_count }}</div>
        </div>
        <div
          :class="['task-tatistics-item', tatisticsIndex === 4 ? 'active' : '']"
          @click="getTaskListApi(4)"
        >
          <div class="item-le">
            <img
              class="side_icon"
              src="@/assets/image/task/side_icon4.png"
              alt=""
            />
            <span>未分配的</span>
          </div>
          <div class="item-ri">{{ taskStatistics.unabsorbed_count }}</div>
        </div>
      </div>
      <div class="land-title">地块</div>
      <div class="land-tab" v-blockLoadMore="loadMoreLand">
        <div
          :class="['land-tab-items', item.id === landIndex ? 'active' : '']"
          v-for="item in landList"
          :key="item.id"
          @click="changeLand(item.title, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="main">
      <PageTop
        :taskShow="taskShow"
        :addShow="taskShow"
        :addShow2="!taskShow"
        :task_type="task_type"
        @addMeans="addTask"
        @showAddAlert="showAddAlert"
        @getTaskSearch="getTaskSearch"
      >
        <div class="adTitle" slot="adTitle">
          <el-tabs @tab-click="handleClick">
            <el-tab-pane label="全部任务" name="全部任务"></el-tab-pane>
            <el-tab-pane label="任务预警" name="任务预警"></el-tab-pane>
            <el-tab-pane
              :label="t"
              :name="t"
              v-for="(t, index) in task_type"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <p slot="addName">发布任务</p>
        <p slot="addName2">新增预警</p>
      </PageTop>
      <div class="task-main" v-show="taskShow">
        <MyTable
          class="animated fadeInUp"
          :tableData="tableData"
          :configTable="configTable"
          @handleComplete="handleComplete"
          @handleStart="handleStart"
          @handleClose="handleClose"
          @handleDelete="handleDelete"
          @handleRecovery="handleRecovery"
          @handleDetail="handleDetail"
          @handleEdit="handleEdit"
        ></MyTable>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <div class="task-main" v-show="!taskShow">
        <MyTable
          class="animated fadeInUp"
          :tableData="alertList"
          :configTable="yujingConfigTable"
          @handleDelete="delAlert"
          @handleEdit="showEditAlert"
          @changeState="changeState"
        ></MyTable>
        <el-pagination
          @current-change="alertListChange"
          :current-page="alertPagination.currentPage"
          :page-size="alertPagination.page_size"
          background
          layout=" prev, pager, next"
          :total="alertPagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <MyNav></MyNav>
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="673px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="setAddClose"
      @open="openDialog"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          {{ PopTitle }}
        </div>
        <div class="add-form">
          <!-- 发布/编辑 -->
          <el-form
            ref="issueForm"
            :model="issueForm"
            :rules="issueRules"
            hide-required-asterisk
            v-if="isContentShow === 1"
          >
            <el-form-item
              label="任务类型"
              :label-width="formLabelWidth"
              prop="title"
            >
              <!-- <el-input
                v-model.trim="issueForm.title"
                autocomplete="off"
                placeholder="请选择任务类型"
                maxlength="20"
              ></el-input> -->
              <el-select v-model="issueForm.title" placeholder="请选择任务类型">
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, index) in task_type"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="地块"
              class="type"
              :label-width="formLabelWidth"
              prop="plot_id"
            >
              <el-select
                v-model="issueForm.plot_id"
                placeholder="选择地块"
                v-loadMore="loadMoreLandData"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in landList"
                  :key="item.id"
                ></el-option>
              </el-select>
              <el-form-item
                label="作物"
                class="type type-col"
                prop="product_id"
              >
                <el-select
                  v-model="issueForm.product_id"
                  placeholder="选择作物"
                  @change="changeCropItems"
                  v-loadMore="loadMoreItemsData"
                >
                  <el-option
                    :label="item.title"
                    :value="item.id"
                    v-for="item in productCropsList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
            <el-form-item
              label="操作流程"
              :label-width="formLabelWidth"
              prop="flow_id"
            >
              <el-select
                v-model="issueForm.flow_id"
                placeholder="请选择操作流程"
                @change="getFlowInfoApi"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in flowList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="t"
              :label-width="formLabelWidth"
              v-for="(t, i) in tagList"
              :key="i"
            >
              <el-input
                :value="tagArrVal[i]"
                autocomplete="off"
                placeholder="请输入流程标签"
                maxlength="20"
                @input="getInput($event, i)"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="选择批次"
              :label-width="formLabelWidth"
              prop="batch_id"
            >
              <el-select
                class="ohter-width"
                v-model="issueForm.batch_id"
                placeholder="请选择批次"
                v-loadMore="loadMoreBatchData"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in batchList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="任务时间"
              :label-width="formLabelWidth"
              prop="taskTime"
            >
              <el-date-picker
                class="my-date-picker"
                v-model="issueForm.taskTime"
                :clearable="false"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="负责人" :label-width="formLabelWidth">
              <myPopover
                ref="leaderPopover"
                :mtype="2"
                :addShow="addShow"
                :list="adminList"
                :leader_id="issueForm.leader_id"
                @addAdminPopover="addAdminPopover"
              ></myPopover>
              <span class="my-popover-tips" v-show="showLeader_idErr"
                >请选择一名负责人</span
              >
            </el-form-item>
            <el-form-item label="参与人" :label-width="formLabelWidth">
              <myPopoverWorker
                :addShow="addShow"
                :list="workerList"
                :workerList="issueForm.worker"
                @addPopover="addAssignList"
              ></myPopoverWorker>
            </el-form-item>
            <el-form-item label="农资" :label-width="formLabelWidth">
              <MyChangeMeans
                :isAddBtnShow="true"
                :addShow="addShow"
                :resource="issueForm.resource"
                @getMeansList="getMeansList"
              ></MyChangeMeans>
            </el-form-item>
            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="content"
            >
              <el-input
                v-model.trim="issueForm.content"
                autocomplete="off"
                placeholder="请输入备注"
                maxlength="200"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- 详情 -->
          <div class="selease" v-if="isContentShow === 2">
            <el-form :model="issueForm" ref="issueForm" class="detail-form">
              <el-form-item label="任务类型" :label-width="formLabelWidth">
                <p>{{ issueForm.title }}</p>
              </el-form-item>
              <el-form-item label="地块" :label-width="formLabelWidth">
                <p>{{ issueForm.plot_title }}</p>
              </el-form-item>
              <el-form-item label="作物" :label-width="formLabelWidth">
                <p>{{ issueForm.product_title }}</p>
              </el-form-item>
              <el-form-item label="操作流程" :label-width="formLabelWidth">
                <p>{{ issueForm.flow_title }}</p>
              </el-form-item>
              <el-form-item
                :label="i"
                :label-width="formLabelWidth"
                v-for="(t, i) in issueForm.flow_data"
                :key="i"
              >
                <p>{{ t }}</p>
              </el-form-item>

              <el-form-item label="任务时间" :label-width="formLabelWidth">
                <p>
                  {{ issueForm.planning_starttime }} 至
                  {{ issueForm.planning_endtime }}
                </p>
              </el-form-item>
              <el-form-item label="负责人" :label-width="formLabelWidth">
                <div class="person-list">
                  <div class="person-items">
                    <img src="../assets/image/task/icon5.png" />
                    <p>{{ issueForm.leader_name }}</p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="参与人" :label-width="formLabelWidth">
                <div class="person-list">
                  <div
                    class="person-items"
                    v-for="(t, i) in issueForm.worker"
                    :key="i"
                  >
                    <img
                      :src="
                        t.photo
                          ? uploadImgs + t.photo
                          : require('../assets/image/task/icon5.png')
                      "
                    />
                    <p>{{ t.name }}</p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="农资" :label-width="formLabelWidth">
                <div class="mean-item-div">
                  <div
                    class="mean-items"
                    v-for="(t, i) in issueForm.resource"
                    :key="i"
                  >
                    <span class="one-txt-cut"
                      >{{ t.title }}{{ t.num }}{{ t.unit }}</span
                    >
                  </div>
                </div>
              </el-form-item>

              <el-form-item label="备注" :label-width="formLabelWidth">
                <p>
                  {{ issueForm.content }}
                </p>
              </el-form-item>
            </el-form>
          </div>
          <!-- 完成 -->
          <div class="complete" v-if="isContentShow === 3">
            <el-form :model="issueForm" ref="issueForm" class="complete-form">
              <el-form-item label="任务类型" :label-width="formLabelWidth">
                <p>{{ issueForm.title }}</p>
              </el-form-item>
              <el-form-item label="地块" :label-width="formLabelWidth">
                <p>{{ issueForm.plot_title }}</p>
              </el-form-item>
              <el-form-item label="作物" :label-width="formLabelWidth">
                <p>{{ issueForm.product_title }}</p>
              </el-form-item>
              <el-form-item label="操作流程" :label-width="formLabelWidth">
                <p>{{ issueForm.flow_title }}</p>
              </el-form-item>
              <el-form-item
                :label="t"
                :label-width="formLabelWidth"
                v-for="(t, i) in tagList"
                :key="i"
              >
                <el-input
                  :value="tagArrVal[i]"
                  autocomplete="off"
                  placeholder="请输入流程标签"
                  maxlength="20"
                  @input="getInput($event, i)"
                ></el-input>
              </el-form-item>
              <el-form-item label="任务时间" :label-width="formLabelWidth">
                <p>
                  {{ issueForm.planning_starttime }} 至
                  {{ issueForm.planning_endtime }}
                </p>
              </el-form-item>
              <el-form-item label="负责人" :label-width="formLabelWidth">
                <div class="person-list">
                  <div class="person-items">
                    <img src="../assets/image/task/icon5.png" />
                    <p>{{ issueForm.leader_name }}</p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="参与人" :label-width="formLabelWidth">
              <div class="person-list">
                  <div
                    class="person-items"
                    v-for="(t, i) in issueForm.worker"
                    :key="i"
                  >
                    <img
                      :src="
                        t.photo
                          ? uploadImgs + t.photo
                          : require('../assets/image/task/icon5.png')
                      "
                    />
                    <p>{{ t.name }}</p>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="农资"
                :label-width="formLabelWidth"
                prop="resource"
              >
                <MyChangeMeans
                  :isAddBtnShow="true"
                  :addShow="addShow"
                  :resource="issueForm.resource"
                  @getMeansList="getMeansList"
                ></MyChangeMeans>
              </el-form-item>
              <el-form-item label="备注" :label-width="formLabelWidth">
                <p>
                  {{ issueForm.content }}
                </p>
              </el-form-item>
              <el-form-item
                label="上传图片"
                :label-width="formLabelWidth"
                prop="imgs"
              >
                <el-upload
                  class="upload-img"
                  ref="upload"
                  :class="{ disabled: isUploadDisabled }"
                  action="https://resource.darsing.net/api/v1/img"
                  name="img"
                  :auto-upload="true"
                  list-type="picture-card"
                  :show-file-list="true"
                  :file-list="fileList"
                  :headers="uploadHeaders"
                  :limit="1"
                  :on-success="handleImgSuccess"
                  :on-error="handleImgFail"
                  :on-exceed="handleImgExceed"
                  :on-change="changePictureUpload"
                  :before-upload="beforeImgUpload"
                  :on-remove="removeFile"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item
                label="上传视频"
                :label-width="formLabelWidth"
                prop="video"
              >
                <el-upload
                  :class="{ disabled: isUploadVideoDisabled }"
                  class="avatar-uploader upload-img upload-video"
                  action="https://resource.darsing.net/api/v1/file"
                  name="file"
                  :show-file-list="true"
                  :auto-upload="true"
                  :file-list="fileVideoList"
                  list-type="text"
                  :headers="uploadHeaders"
                  :limit="1"
                  :on-success="handleVideoSuccess"
                  :before-upload="beforeVideoUpload"
                  :on-change="changeVideoUpload"
                  :on-progress="uploadVideoProcess"
                  :on-remove="removeVideoFile"
                >
                  <video
                    width="100%"
                    height="200px"
                    v-if="videoUrl"
                    controls
                    autoplay
                    :key="menuKey"
                    :src="uploadFiles + '/' + videoUrl"
                  ></video>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <el-progress
                  v-show="videoFlag"
                  type="line"
                  :percentage="videoUploadPercent"
                ></el-progress>
              </el-form-item>
            </el-form>
          </div>
          <div class="dialog-footer" v-show="isContentShow !== 2">
            <el-button
              class="ok-button"
              type="primary"
              @click="okAdd('issueForm')"
              >确 定</el-button
            >
            <el-button class="no-button" @click="noAdd('issueForm')"
              >取 消</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <el-dialog
      class="alertDialog"
      :title="isEdit ? '编辑报警' : '新增报警'"
      :visible.sync="alertFormVisible"
      :close-on-click-modal="false"
      width="700px"
      @close="resetAlertCheck"
    >
      <el-form
        :model="alertForm"
        :rules="alertRules"
        ref="alertRef"
        hide-required-asterisk
      >
        <el-form-item
          label="名称"
          :label-width="alertFormLabelWidth"
          prop="title"
        >
          <el-input v-model="alertForm.title" placeholder="输入名称"></el-input>
        </el-form-item>
        <el-form-item
          label="设备"
          :label-width="alertFormLabelWidth"
          prop="unitId"
        >
          <el-select
            v-model="alertForm.unitId"
            placeholder="选择设备"
            filterable
            remote
            :remote-method="searchUnit2"
            :loading="loading"
            @change="searchUnit2(alertForm.unitId)"
          >
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="采集单元"
          :label-width="alertFormLabelWidth"
          prop="deviceIdItem"
        >
          <el-select
            v-model="alertForm.deviceIdItem"
            placeholder="选择采集单元"
            filterable
            remote
            :remote-method="searchDevice2"
            :loading="loading"
            @change="changeItem"
          >
            <el-option
              v-for="item in deviceIdItemList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="触发条件"
          :label-width="alertFormLabelWidth"
          prop="trigger"
        >
          <el-select v-model="alertForm.trigger" placeholder="选择触发条件">
            <el-option
              v-for="item in triggerList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="阈值"
          :label-width="alertFormLabelWidth"
          prop="threshold"
        >
          <el-input
            class="short-input"
            v-model="alertForm.threshold"
            placeholder="输入数值"
          ></el-input>
          <span class="units">{{ unitsVal }}</span>
        </el-form-item>
        <el-form-item
          label="监测间隔"
          :label-width="alertFormLabelWidth"
          prop="time_value"
          class="my-time_value"
        >
          <el-input
            v-model="alertForm.time_value"
            placeholder="输入数值,最小15分钟"
          ></el-input>
          <el-select
            class="time_unit"
            v-model="alertForm.time_unit"
            placeholder="选择单位"
          >
            <el-option
              v-for="item in time_unitList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="通知间隔"
          :label-width="alertFormLabelWidth"
          prop="notify_interval"
        >
          <el-input
            class="short-input"
            v-model="alertForm.notify_interval"
            placeholder="输入数值,单位小时"
          ></el-input>
          <span class="units">小时</span>
        </el-form-item>
        <el-form-item label="通知方式" :label-width="alertFormLabelWidth">
          <template>
            <el-radio v-model="alertForm.notify_type" :label="0"
              >站内信</el-radio
            >
            <el-radio v-model="alertForm.notify_type" :label="1"
              >站内信+短信</el-radio
            >
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="alertFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="isEdit ? editAlert() : addAlert()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
        <!-- title="任务详情" -->
    <el-dialog custom-class="detail-list-dialog" :visible.sync="detailShow">
      <div class="detail-box">
        <div class="detail-dialog-title"><span>任务详情</span></div>
        <div class="list-main">
          <MyTable
            class="animated fadeInUp"
            :tableData="detailListData"
            :configTable="detailListConfigTable"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getTaskListApi,
  getTaskInfoApi,
  deleteTaskApi,
  postAddTaskApi,
  getProductListApi,
  getPlotListApi,
  getFlowListApi,
  getBatchListApi,
  postEditTaskStateApi,
  postEditTaskApi,
  getMemberListApi,
  getFlowInfoApi,
  getMonitorListApi,
  delMonitorDelApi,
  getEquipmentListApi,
  getEquipmentChildListApi,
  postMonitorAppendApi,
  getMonitorInfoApi,
  postMonitorEditApi,
  postMonitorEditOffApi,
  postMonitorEditOnApi,
  getEquipmentGroupApi,
  getMonitorEquipmentListApi,
  getFarmInfoApi,
  getTaskStatisticsApi,
} from "@/request/api";
import { mapState, mapMutations } from "vuex";
import { editTime } from "@/utils/editTime";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTop: () => import("@/components/PageTop.vue"),
    MyPopover: () => import("@/components/MyPopover.vue"),
    myPopoverWorker: () => import("@/components/myPopoverWorker.vue"),
    MyChangeMeans: () => import("@/components/MyChangeMeans.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
  },
  data() {
    return {
      unitsVal: "",
      tatisticsIndex: "",
      showLeader_idErr: false,
      task_type: [],
            detailShow: false,
      detailListData: [],
      detailListConfigTable: [
        {
          prop: "title",
          label: "任务类型",
          width: "118",
        },
        {
          prop: "plot_title",
          label: "地块",
          width: "",
        },
        {
          prop: "product_title",
          label: "产品",
          width: "93",
        },
        {
          prop: "flow_title",
          label: "操作流程",
          width: "97",
        },
        {
          prop: "planning_starttime",
          label: "开始时间",
          width: "218",
        },
        {
          prop: "planning_endtime",
          label: "结束时间",
          width: "218",
        },
        {
          prop: "state",
          label: "任务状态",
          width: "80",
        },
        {
          prop: "creater_name",
          label: "所属基地",
          width: "180",
        },
        {
          prop: "leader_name",
          label: "负责人",
          width: "80",
        },
      ],
      // 上传视频
      videoUrl: "",
      videoFileUrl: "",
      // 用来强制刷新
      menuKey: 1,
      videoFlag: false,
      videoUploadPercent: 0,
      // 上传图片
      imageUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      isUploadDisabled: false, //控制上传按钮
      isUploadVideoDisabled: false,
      fileList: [],
      fileVideoList: [],
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      // 弹窗
      addShow: false,
      isContentShow: null,
      PopTitle: "",
      // 发布任务
      task_id: "",
      issueForm: {
        title: "",
        flow_id: "",
        batch_id: "",
        plot_id: "",
        product_id: "",
        planning_endtime: "",
        planning_starttime: "",
        leader_id: 0,
        worker_ids: [],
        worker: [],
        content: "",
        resource: [],
        video: "",
        weight: 0,
        flow_data: {},
        imgs: "",
        taskTime: [],
      },
      resource: {},
      issueRules: {
        title: [{ required: true, message: "请选择任务类型", trigger: "blur" }],
        resource: [{ required: true, message: "请添加农资", trigger: "input" }],
        flow_id: [
          { required: true, message: "请选择操作流程", trigger: "change" },
        ],
        batch_id: [
          { required: true, message: "请选择批次", trigger: "change" },
        ],
        plot_id: [
          {
            required: true,
            message: "请选择地块",
            trigger: "change",
          },
        ],
        product_id: [
          { required: true, message: "请选择作物", trigger: "change" },
        ],
        taskTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      formLabelWidth: "110px",
      // 表格-2
      yujingConfigTable: [
        {
          prop: "title",
          label: "名称",
          width: "197",
        },
        {
          prop: "equipment_title",
          label: "设备",
          width: "197",
        },
        {
          prop: "child_title",
          label: "采集单元",
          width: "197",
        },
        {
          prop: "threshold",
          label: "阈值",
          width: "197",
        },
        {
          prop: "last_monitor_state",
          label: "检测状态",
          width: "258",
        },

        {
          prop: "",
          label: "操作",
          width: "280",
          delType: true,
          editType: true,
          statusType: true,
        },
      ],
      // 表格
      configTable: [
        {
          prop: "title",
          label: "任务类型",
          width: "118",
        },
        {
          prop: "plot_title",
          label: "地块",
          width: "117",
        },
        {
          prop: "product_title",
          label: "作物",
          width: "93",
        },
        {
          prop: "flow_title",
          label: "操作流程",
          width: "97",
        },
        {
          prop: "planning_starttime",
          label: "开始时间",
          width: "218",
        },
        {
          prop: "planning_endtime",
          label: "结束时间",
          width: "218",
        },
        {
          prop: "state",
          label: "任务状态",
          width: "80",
        },
        {
          prop: "creater_name",
          label: "指派人",
          width: "80",
        },
        {
          prop: "leader_name",
          label: "负责人",
          width: "80",
        },
        {
          prop: "",
          label: "操作",
          width: "",
          recoveryType2: true,
          detailType2: true,
          delType2: true,
          closeType2: true,
          completeType2: true,
          startType2: true,
          editType2: true,
        },
      ],
      tableData: [],
      landIndex: undefined,
      // 分页
      total: 20, // 总数
      current_page: 1, // 当前页
      // 产品分页
      productCropsList: [],
      productTotal: 20,
      productCurrent_page: 1,
      // 地块分页
      landList: [],
      landTotal: 20,
      landCurrent_page: 1,
      // 批次
      batchList: [],
      batchTotal: 20,
      batchCurrent_page: 1,
      // 流程
      flowList: [],
      tagList: [],
      // 任务状态
      custom_state: undefined,
      // 人员
      list: [],
      workerList: [],
      adminList: [],
      // 搜索条件
      searchTit: undefined,
      searchState: undefined,
      searchLeader_id: undefined,
      searchPlanning_endtime: undefined,
      searchPlanning_starttime: undefined,
      taskShow: true,
      alertList: [],
      alertId: null,
      isEdit: false,
      alertFormLabelWidth: "100px",
      alertFormVisible: false,
      alertForm: {
        title: "",
        deviceId: "",
        deviceIdItem: "",
        equipment_title: "",
        unitId: "",
        trigger: "",
        threshold: "",
        type: 1,
        linkman: "",
        desc: "",
        state: 0,
        time_value: null,
        time_unit: null,
        notify_interval: null,
        notify_type: 0,
      },
      alertRules: {
        title: [
          { required: true, message: "输入名称", trigger: "blur" },
          { min: 2, message: "长度不低于2个字符", trigger: "blur" },
        ],
        deviceIdItem: [
          { required: true, message: "选择采集单元", trigger: "blur" },
        ],
        unitId: [{ required: true, message: "选择设备", trigger: "blur" }],
        trigger: [{ required: true, message: "输入触发条件", trigger: "blur" }],
        threshold: [{ required: true, message: "输入阈值", trigger: "blur" }],
        time_value: [
          { required: true, message: "输入监测间隔", trigger: "blur" },
        ],
      },
      alertPagination: {
        currentPage: 1,
        page_size: 10,
        total: 0,
      },
      loading: false,
      allDeviceList: [],
      deviceList: [],
      deviceIdItemList: [],
      deviceId: null,
      deviceIdItem: null,
      unitList: [],
      unitId: null,
      triggerList: [
        { title: "大于", id: 1 },
        { title: "等于", id: 0 },
        { title: "小于", id: -1 },
      ],
      time_unitList: [
        { title: "周", id: 1 },
        { title: "天", id: 2 },
        { title: "时", id: 3 },
        { title: "分", id: 4 },
      ],
      tagArrVal: [],
      taskStatistics: {},
      plot_name: undefined,
    };
  },
  watch: {
    "issueForm.product_id": {
      handler() {
        this.batchList = [];
      },
    },
    // 窗口关闭
    addShow(newVal) {
      if (!newVal) {
        this.tagList = [];
        this.resource = {};
        this.issueForm = {
          title: "",
          flow_id: "",
          batch_id: "",
          plot_id: "",
          product_id: "",
          planning_endtime: "",
          planning_starttime: "",
          leader_id: 0,
          worker_ids: [],
          worker: [],
          content: "",
          resource: [],
          video: "",
          weight: 0,
          flow_data: {},
          imgs: "",
          taskTime: [],
        };
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      uploadFiles: (state) => state.app.uploadFiles,
      taskCount: (state) => state.user.taskCount,
    }),
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
    this.getTaskListApi();
    this.getLandLists();
    this.getAlertList();
    this.searchUnit();
    this.getMemberListApi();
    this.getProductListApi();
    this.getFarmInfoApi();
    this.getTaskStatisticsApi();
  },

  methods: {
    ...mapMutations("user", ["SET_TASK_COUNT"]),
    changeLand(title, id) {
      this.landIndex = id;
      this.plot_name = title;
      this.getTaskListApi();
    },
    async getTaskStatisticsApi() {
      const { code, results } = await getTaskStatisticsApi({
        manor_id: this.farmID,
      });
      if (code != 0) return;
      this.taskStatistics = results;
    },
    changeItem(e) {
      let { data_unit } = this.deviceIdItemList.find((item) => {
        return item.id == e;
      });
      this.unitsVal = data_unit;
    },
    async getFarmInfoApi() {
      // showFullScreenLoading();
      const { code, results } = await getFarmInfoApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        if (results.task_type) {
          this.task_type = results.task_type.split(",");
        }
      }
    },
    // 获取报警列表
    async getAlertList(page_num) {
      const res = await getMonitorListApi({
        page_num: page_num,
        page_size: this.alertPagination.page_size,
        manor_id: this.farmID,
      });
      this.alertPagination.total = res.results.count;
      this.alertList = res.results.data;
    },
    /* 切换报警列表分页 */
    alertListChange(val) {
      this.getAlertList(val);
    },
    /* 清空报警表单 */
    initAlertForm() {
      this.alertForm.title = "";
      this.alertForm.deviceIdItem = "";
      this.alertForm.deviceId = "";
      this.alertForm.unitId = "";
      this.alertForm.trigger = "";
      this.alertForm.threshold = "";
      this.alertForm.type = 1;
      this.alertForm.linkman = "";
      this.alertForm.desc = "";
      this.alertForm.state = 0;
      this.alertForm.time_value = null;
      this.alertForm.time_unit = null;
      this.alertForm.notify_interval = null;
      this.alertForm.notify_type = 0;
    },
    /* 清空表单校验 */
    resetAlertCheck() {
      this.$refs.alertRef.resetFields();
    },
    /* 获取设备列表  channel_id */
    async searchDevice() {
      const { data: res } = await getEquipmentGroupApi({
        manor_id: this.farmID,
      });
      this.allDeviceList = res;
    },
    /* 搜索获取设备列表 */
    searchDevice2(query) {
      if (query != "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const { data: res } = await getEquipmentListApi({
            title: query,
            manor_id: this.farmID,
          });
          this.deviceList = res.results;
        }, 200);
      } else {
        this.deviceList = [];
      }
    },
    async searchUnit() {
      const res = await getMonitorEquipmentListApi({
        manor_id: this.farmID,
      });
      this.unitList = res.results;
    },
    /* 搜索获取采集单元列表 */
    searchUnit2(query) {
      this.alertForm.equipment_title = this.unitList.find((item) => {
        return item.id == query;
      }).title;

      if (query != "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const res = await getEquipmentChildListApi({
            table_id: query,
            // title: query,
            manor_id: this.farmID,
          });
          this.deviceIdItemList = res.results;
        }, 200);
      } else {
        this.deviceIdItemList = [];
      }
    },
    /* 显示新增报警对话框 */
    async showAddAlert() {
      this.deviceList = this.allDeviceList;
      this.initAlertForm();
      this.isEdit = false;
      this.alertFormVisible = true;
    },

    /* 提交新增报警 */
    async addAlert() {
      this.$refs.alertRef.validate(async (valid) => {
        if (valid) {
          let tempAlert = {};
          tempAlert.title = this.alertForm.title;
          tempAlert.equipment_id = this.alertForm.unitId;
          tempAlert.equipment_title = this.alertForm.equipment_title;
          tempAlert.child_id = this.alertForm.deviceIdItem;
          tempAlert.comparison = this.alertForm.trigger;
          tempAlert.threshold = parseFloat(this.alertForm.threshold);
          tempAlert.time_value = parseInt(this.alertForm.time_value);
          tempAlert.time_unit = this.alertForm.time_unit;
          tempAlert.notify_interval = parseInt(this.alertForm.notify_interval);
          tempAlert.notify_type = this.alertForm.notify_type;
          tempAlert.state = this.alertForm.state;
          tempAlert.manor_id = this.farmID;
          await postMonitorAppendApi(tempAlert);
          this.alertFormVisible = false;
          this.getAlertList();
        }
      });
    },
    /* 显示编辑报警对话框 */
    async showEditAlert({ id }) {
      this.isEdit = true;
      this.deviceList = this.allDeviceList;
      this.alertId = id;
      const res = await getMonitorInfoApi({
        monitor_id: id,
      });
      this.alertForm.title = res.results.title;
      this.alertForm.deviceId = res.results.equipment_id;
      this.alertForm.unitId = res.results.equipment_id;
      this.alertForm.deviceIdItem = res.results.child_id;
      this.alertForm.trigger = res.results.comparison;
      this.alertForm.threshold = res.results.threshold;
      this.alertForm.time_value = res.results.time_value;
      this.alertForm.time_unit = res.results.time_unit;
      this.alertForm.notify_interval = res.results.notify_interval;
      this.alertForm.notify_type = res.results.notify_type;
      this.alertFormVisible = true;
      const res3 = await getEquipmentChildListApi({
        table_id: res.results.equipment_id,
        manor_id: this.farmID,
      });
      this.deviceIdItemList = res3.results;
    },
    /* 提交编辑报警 */
    async editAlert() {
      this.$refs.alertRef.validate(async (valid) => {
        if (valid) {
          var tempAlert = {};
          tempAlert.monitor_id = this.alertId;
          tempAlert.title = this.alertForm.title;
          tempAlert.equipment_id = this.alertForm.unitId;
          tempAlert.child_id = this.alertForm.deviceIdItem;
          tempAlert.comparison = this.alertForm.trigger;
          tempAlert.threshold = parseFloat(this.alertForm.threshold);
          tempAlert.time_value = parseInt(this.alertForm.time_value);
          tempAlert.time_unit = this.alertForm.time_unit;
          tempAlert.notify_interval = parseInt(this.alertForm.notify_interval);
          tempAlert.notify_type = this.alertForm.notify_type;
          tempAlert.state = this.alertForm.state;
          const res = await postMonitorEditApi(tempAlert);
          if (res.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
            return;
          }
          this.alertFormVisible = false;
          this.getAlertList();
        }
      });
    },
    async postMonitorEditOffApi(item) {
      const res = await postMonitorEditOffApi({
        monitor_id: item.id,
      });
      if (res.code != 0) {
        this.$notify.error({
          title: "错误",
          message: res.msg,
        });
      } else {
        this.$notify({
          title: "提示",
          message: "设置成功！",
          type: "success",
        });
        this.getAlertList();
      }
    },
    async postMonitorEditOnApi(item) {
      const res = await postMonitorEditOnApi({
        monitor_id: item.id,
      });
      if (res.code != 0) {
        this.$notify.error({
          title: "错误",
          message: res.msg,
        });
      } else {
        this.$notify({
          title: "提示",
          message: "设置成功！",
          type: "success",
        });
        this.getAlertList();
      }
    },
    /* 更改报警状态 */
    changeState(item) {
      var string = item.state ? "关闭" : "开启";
      this.$confirm("确认" + string + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          item.state
            ? this.postMonitorEditOffApi(item)
            : this.postMonitorEditOnApi(item);
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
            type: "info",
          });
        });
    },
    /* 删除报警 */
    async delAlert({ id }) {
      this.$confirm("确认删除该报警？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delMonitorDelApi({
            monitor_id: id,
            // manor_id: this.farmID,
          });
          if (res.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.msg,
            });
          } else {
            this.$notify({
              title: "提示",
              message: "设置成功！",
              type: "success",
            });
          }
          this.getAlertList();
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
            type: "info",
          });
        });
    },
    handleClick(tab) {
      this.plot_name = undefined;
      this.landIndex = undefined;
      if (tab.name === "任务预警") {
        this.taskShow = false;
      } else if (tab.name === "全部任务") {
        this.searchTit = undefined;
        this.taskShow = true;
        this.getTaskListApi();
      } else {
        this.searchTit = tab.name;
        this.getTaskListApi();
        this.taskShow = true;
      }
    },
    getInput(e, i) {
      this.tagArrVal[i] = e;
      this.$forceUpdate();
    },
    // 获取流程详情
    async getFlowInfoApi() {
      this.tagArrVal = [];
      this.tagList = [];
      const { code, results } = await getFlowInfoApi({
        flow_id: this.issueForm.flow_id,
      });
      if (code === 0) {
        this.tagList = results.tag;
      }
    },
    // 获取人员列表
    async getMemberListApi() {
      const { code, results } = await getMemberListApi({
        manor_id: this.farmID,
        pn: this.memberCurrent_page,
        custom_state: 1,
      });
      if (code === 0) {
        this.list = [...this.list, ...results.data];
        this.memberTotal = results.count;
        this.memberCurrent_page = results.pn;
        this.adminList = this.list.filter((item) => {
          return item.mtype === 2;
        });
        this.workerList = this.list.filter((item) => {
          if (item.mtype === 3) {
            this.$set(item, "checked", false);
          }
          return item.mtype === 3;
        });
      }
    },
    // 搜索
    getTaskSearch({
      // title,
      leader_id,
      state,
      planning_endtime,
      planning_starttime,
    }) {
      // this.searchTit = title;
      this.searchLeader_id = leader_id;
      this.searchState = state;
      this.searchPlanning_endtime = planning_endtime;
      this.searchPlanning_starttime = planning_starttime;
      this.getTaskListApi();
    },
    // 获取基础信息-农资
    getMeansList(val) {
      this.resource = val;
    },
    // 获取流程列表
    async changeCropItems() {
      const { code, results } = await getFlowListApi({
        manor_id: this.farmID,
        product_id: this.issueForm.crop,
      });
      if (code === 0) {
        this.flowList = results;
      }
      this.getBatchListApi();
    },
    // 获取批次列表
    async getBatchListApi(pn = 1) {
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        product_id: this.issueForm.product_id,
        pn,
      });

      if (code === 0) {
        this.batchList = [...this.batchList, ...results.data];
        this.batchCurrent_page = results.pn;
        this.batchTotal = results.count;
      }
    },
    // 获取地块列表
    async getLandLists(pn = 1) {
      const { code, results } = await getPlotListApi({
        manor_id: this.farmID,
        pn,
        ps: 20,
      });
      if (code === 0) {
        this.landList = [...this.landList, ...results.data];
        this.landCurrent_page = results.pn;
        this.landTotal = results.count;
      }
    },
    loadMoreBatchData() {
      if (this.batchList.length && this.batchList.length == this.batchTotal)
        return;
      this.batchCurrent_page++;
      this.getBatchListApi(this.batchCurrent_page);
    },
    // 更多地块
    loadMoreLandData() {
      if (this.landList.length && this.landList.length == this.landTotal)
        return;
      this.landCurrent_page++;
      this.getLandLists(this.landCurrent_page);
    },
    // 更多地块
    loadMoreLand() {
      if (this.landList.length && this.landList.length == this.landTotal)
        return;
      this.landCurrent_page++;
      this.getLandLists(this.landCurrent_page);
    },
    // 更多产品
    loadMoreItemsData() {
      if (
        this.productCropsList.length &&
        this.productCropsList.length == this.productTotal
      )
        return;
      this.productCurrent_page++;
      this.getProductListApi(this.productCurrent_page);
    },
    // 获取产品列表
    async getProductListApi(pn = 1) {
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.productTotal = results.count;
        this.productCurrent_page = results.pn;
        this.productCropsList = [...this.productCropsList, ...results.data];
      }
    },
    // 获取任务列表
    async getTaskListApi(taskType) {
      this.tatisticsIndex = taskType;
      let task_type = taskType || undefined;
      showFullScreenLoading();
      const { code, results } = await getTaskListApi({
        manor_id: this.farmID,
        pn: this.current_page,
        title: this.searchTit,
        leader_id: this.searchLeader_id,
        state: this.searchState,
        start_time: this.searchPlanning_starttime,
        end_time: this.searchPlanning_endtime,
        plot_name: this.plot_name,
        task_type,
      });
      if (code === 0) {
        this.total = results.count;
        this.current_page = results.pn;
        this.tableData = results.data;
        this.tableData.forEach((item) => {
          if (item.state == 0) {
            item.state = "待执行";
          } else if (item.state == 1) {
            item.state = "执行中";
          } else if (item.state == 2) {
            item.state = "已完成";
          } else {
            item.state = "已关闭";
          }
        });
      }
      tryHideFullScreenLoading();
    },
    // 上传视频
    // 完成上传
    handleVideoSuccess(res) {
      // 进度条恢复到初始状态
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      ++this.menuKey;
      this.videoUrl = res.Data.filename;
    },
    beforeImgUpload(file) {
      // 文件类型
      const isImg =
        file.type === "image/png" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/jpg" ||
        file.type === "image/psd" ||
        file.type === "image/tif" ||
        file.type === "image/jpeg";
      if (!isImg) {
        this.$notify.error({
          title: "错误",
          message: "格式错误",
        });
      }
      return isImg;
    },
    beforeVideoUpload(file) {
      // 文件类型
      const isVideo =
        file.type === "video/mp4" ||
        file.type === "video/ogg" ||
        file.type === "video/flv" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";
      // 限制文件最大不能超过 500M
      const isLtMb = file.size / 1024 / 1024 < 32;

      if (!isVideo) {
        this.$notify.error({
          title: "错误",
          message: "格式错误",
        });
      }
      if (!isLtMb) {
        this.$notify.error({
          title: "错误",
          message: "文件大小不能超过30MB",
        });
      }
      return isVideo && isLtMb;
    },

    //进度条
    uploadVideoProcess(event, file) {
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage);
    },

    removeVideoFile() {
      this.videoUrl = "";
      this.videoFileUrl = "";
      this.isUploadVideoDisabled = false;
    },
    // on-change钩子
    changePictureUpload(file, fileList) {
      this.isUploadDisabled = fileList.length >= 1;
    },
    changeVideoUpload(file, fileList) {
      this.videoFileUrl = fileList[0].raw;
      this.isUploadVideoDisabled = fileList.length >= 1;
    },
    // 图片上传成功
    handleImgSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    // 上传失败
    handleImgFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    // 超出上传数量 files, fileList
    handleImgExceed() {
      this.$notify({
        title: "提示",
        message: "最多上传一张图片",
      });
    },
    // 完成
    async handleComplete({ id }) {
      this.task_id = id;
      this.custom_state = 2;
      const { code, results } = await getTaskInfoApi({ task_id: id });
      if (code === 0) {
        results.worker.forEach((item) => {
          this.$set(item, "checked", true);
        });
        this.tagList = Object.keys(results.flow_data);
        this.tagArrVal = Object.values(results.flow_data);
        this.issueForm = results;
        if (Array.isArray(results.resource)) {
          results.resource.forEach((item) => {
            this.resource[item.id] = item.num;
          });
        }
        this.PopTitle = "任务详情";
        this.isContentShow = 3;
        this.addShow = true;
      }
    },
    // 开始
    handleStart({ id }) {
      this.task_id = id;
      this.custom_state = 1;
      this.postEditTaskStateApi();
    },

    // 任务状态
    async postEditTaskStateApi() {
      const { code, msg } = await postEditTaskStateApi({
        task_id: this.task_id,
        state: this.custom_state,
      });
      if (code === 0) {
        this.getTaskListApi();
        if (this.custom_state === -1) {
          this.$notify({
            title: "提示",
            message: "已关闭",
            type: "success",
          });
          this.SET_TASK_COUNT({ taskCount: this.taskCount - 1 });
        } else if (this.custom_state === 0) {
          this.$notify({
            title: "提示",
            message: "已恢复",
            type: "success",
          });
        } else if (this.custom_state === 1) {
          this.$notify({
            title: "提示",
            message: "已开始",
            type: "success",
          });
          this.SET_TASK_COUNT({ taskCount: this.taskCount + 1 });
        } else if (this.custom_state === 2) {
          this.getTaskListApi();
          this.$notify({
            title: "提示",
            message: "已完成",
            type: "success",
          });
          this.addShow = false;
          this.SET_TASK_COUNT({ taskCount: this.taskCount - 1 });
        }
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.issueForm.clearValidate();
      });
    },
    // 提交关闭
    handleClose({ id }) {
      this.task_id = id;
      this.custom_state = -1;
      this.$confirm("此操作将关闭该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.postEditTaskStateApi();
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },

    // 删除任务记录
    handleDelete({ id }) {
      this.$confirm("此操作将删除该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await deleteTaskApi({ task_id: id });
          if (code === 0) {
            this.getTaskListApi();
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
        })
        .catch(() => {
          this.$notify({
            title: "提示",
            message: "已取消操作",
          });
        });
    },
    // 恢复
    handleRecovery({ id }) {
      this.task_id = id;
      this.custom_state = 0;
      this.postEditTaskStateApi();
    },
    // 编辑
    async handleEdit({ id }) {
      this.task_id = id;
      const { code, results } = await getTaskInfoApi({ task_id: id });
      if (code === 0) {
        results.worker.forEach((item) => {
          this.$set(item, "checked", true);
        });
        // this.tagList = Object.values(results.flow_data);
        this.tagList = Object.keys(results.flow_data);
        this.tagArrVal = Object.values(results.flow_data);
        this.issueForm = results;
        if (Array.isArray(results.resource)) {
          results.resource.forEach((item) => {
            this.resource[item.id] = item.num;
          });
        }
        this.$set(this.issueForm, "taskTime", [
          results.planning_starttime,
          results.planning_endtime,
        ]);
        this.PopTitle = "编辑任务";
        this.isContentShow = 1;
        this.$forceUpdate();
        this.addShow = true;
      }
    },
    // 详情
    async handleDetail(row) {
      const { code, results } = await getTaskInfoApi({ task_id: row.id });
      if (code === 0) {
        this.tagList = [];
        this.tagList = Object.values(results.flow_data);
        this.issueForm = results;
        this.PopTitle = "任务详情";
        this.addShow = true;
        this.isContentShow = 2;
      }
    },
    // icon图标-删除图片
    handleRemove(file) {
      this.removeFile(file);
    },
    // 删除图片
    removeFile(file) {
      let fileLists = this.$refs.upload.uploadFiles;
      this.isUploadDisabled = !fileLists.length >= 1;
      let index = fileLists.findIndex((fileItem) => {
        return fileItem.uid === file.uid;
      });
      fileLists.splice(index, 1);
      this.fileList.splice(index, 1);
      this.imageUrl = "";
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 管理者
    addAdminPopover(val) {
      this.issueForm.leader_id = val || "";
    },
    // 参与者
    addAssignList(val) {
      this.issueForm.worker_ids = [];
      val.forEach((item) => {
        this.issueForm.worker_ids.push(item.id);
      });
    },
    // 窗口-取消
    noAdd() {
      this.addShow = false;
    },
    // 修改状态-完成
    okAdd(formName) {
      // 任务时间
      let planning_starttime, planning_endtime;
      if (this.issueForm.planning_endtime) {
        planning_starttime = this.issueForm.planning_starttime;
        planning_endtime = this.issueForm.planning_endtime;
      } else {
        planning_starttime = editTime(this.issueForm.taskTime[0]);
        planning_endtime = editTime(this.issueForm.taskTime[1]);
      }
      // 参与人
      if (typeof this.issueForm.worker_ids === "string") {
        this.$set(
          this.issueForm,
          "worker_ids",
          this.issueForm.worker_ids.split(",")
        );
        this.issueForm.worker_ids = this.issueForm.worker_ids.map((item) => {
          return (item = +item);
        });
        this.$forceUpdate();
      }
      // 流程标签
      this.tagArrVal.forEach((item, index) => {
        this.issueForm.flow_data[this.tagList[index]] = item;
      });

      if (this.isContentShow === 2) {
        this.addShow = false;
      } else if (this.isContentShow === 1) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            if (!this.issueForm.leader_id) {
              this.showLeader_idErr = true;
              return;
            } else {
              this.showLeader_idErr = false;
            }
            if (this.PopTitle === "编辑任务") {
              const { code } = await postEditTaskApi({
                manor_id: this.farmID,
                task_id: this.task_id,
                product_id: this.issueForm.product_id,
                plot_id: this.issueForm.plot_id,
                batch_id: this.issueForm.batch_id,
                flow_id: this.issueForm.flow_id,
                leader_id: this.issueForm.leader_id,
                worker_ids: this.issueForm.worker_ids,
                title: this.issueForm.title,
                weight: this.issueForm.weight,
                planning_starttime,
                planning_endtime,
                flow_data: this.issueForm.flow_data,
                resource: this.resource,
                content: this.issueForm.content,
              });
              if (code === 0) {
                this.getTaskListApi();
                this.addShow = false;

                this.$notify({
                  title: "成功",
                  message: "已修改",
                  type: "success",
                });
              }
            } else {
              // 添加任务
              const { code } = await postAddTaskApi({
                manor_id: this.farmID,
                flow_id: this.issueForm.flow_id,
                batch_id: this.issueForm.batch_id,
                plot_id: this.issueForm.plot_id,
                product_id: this.issueForm.product_id,
                planning_endtime,
                planning_starttime,
                leader_id: this.issueForm.leader_id,
                worker_ids: this.issueForm.worker_ids,
                content: this.issueForm.content,
                title: this.issueForm.title,
                resource: this.resource,
                flow_data: this.issueForm.flow_data,
                weight: 0,
              });
              if (code === 0) {
                this.getTaskListApi();
                this.addShow = false;
                this.$notify({
                  title: "成功",
                  message: "已添加",
                  type: "success",
                });
              }
            }
          } else {
            return false;
          }
        });
        // 提交完成
      } else if (this.isContentShow === 3) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const { code } = await postEditTaskApi({
              manor_id: this.farmID,
              task_id: this.task_id,
              product_id: this.issueForm.product_id,
              plot_id: this.issueForm.plot_id,
              batch_id: this.issueForm.batch_id,
              flow_id: this.issueForm.flow_id,
              leader_id: this.issueForm.leader_id,
              worker_ids: this.issueForm.worker_ids,
              title: this.issueForm.title,
              weight: this.issueForm.weight,
              flow_data: this.issueForm.flow_data,
              planning_starttime,
              planning_endtime,
              photo: this.imageUrl,
              video: this.videoUrl,
              resource: this.resource,
              content: this.issueForm.content,
            });
            if (code === 0) {
              this.postEditTaskStateApi();
            }
          } else {
            return false;
          }
        });
      }
    },
    setAddClose() {
      this.addShow = false;
    },

    handleCurrentChange(val) {
      this.current_page = val;
      this.getTaskListApi();
    },
    // 发布任务
    addTask() {
      this.PopTitle = "发布任务";
      this.addShow = true;
      this.isContentShow = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.task {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: space-between;
  padding: 62px 48px 0;
  background-color: #071a2c;

  .task-side {
    width: 312px;
    height: 100vh;

    background: #142d4c;
    padding: 34px 21px;
    margin-top: 16px;
    .task-side-adTitle {
      width: 100%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
      margin-bottom: 27px;
    }
    .statistics {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      margin-bottom: 28px;
      .task-tatistics-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 26px;
        margin-bottom: 12px;
        border-radius: 2px;
        background: #224d7f;
        cursor: pointer;
        &.active {
          background-color: #3e90e5;
        }
        .item-le {
          display: flex;
          align-items: center;
          .side_icon {
            width: 24px;
            height: 24px;
            margin-right: 16px;
          }
          span {
            font-size: 14px;
            color: #fff;
          }
        }
        .item-ri {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .land-title {
      font-size: 18px;
      color: #fff;
      margin-bottom: 18px;
    }

    .land-tab {
      width: 100%;
      height: 60%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .land-tab-items {
        width: 100%;
        height: 32px;
        text-align: center;
        line-height: 32px;
        background: #173355;
        border: 1px solid #1b3e68;
        border-radius: 2px;
        font-size: 14px;
        color: #fff;
        margin-bottom: 18px;
        cursor: pointer;
        &.active {
          background-color: #3e90e5;
        }
      }
    }
  }
  .main {
    width: calc(100% - 324px);
    height: 98%;
  }

  /deep/.adTitle {
    display: flex;
    height: auto !important;
    border-left: initial !important;
    margin-bottom: 0 !important;

    .el-tabs__item {
      color: #fff;
    }
  }
  /deep/.ad-bot {
    justify-content: flex-end !important;
  }
  .task-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0e2138;
    padding: 38px 48px 22px;

    .time-box {
      display: flex;
      justify-content: center;
      flex-direction: column;

      p {
        margin-bottom: 10px;
      }

      .real {
        color: #67c23a;
        margin-bottom: 0px;
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 673px !important;
      background-color: inherit;
      margin-top: 0 !important;

      .el-dialog__header {
        padding: 16px 20px 10px;
      }
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 71px;
      right: 52px;
      font-weight: bold;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 673px;
      height: 861px;
      background: url(../assets/image/task/popbgc.png) no-repeat center center;
      background-size: cover;
      padding: 45px 55px 0;

      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      .add-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        height: calc(100% - 150px);

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }

        /deep/.upload-img {
          .el-upload--picture-card {
            width: 40px;
            height: 40px;
            background: #081c30;
            border: 1px solid #57708d;
            border-radius: 3px;
            position: relative;
          }

          .el-upload--picture-card i {
            font-size: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &.disabled {
            .el-upload--picture-card {
              display: none;
            }
          }
        }
        /deep/.upload-video {
          .el-upload.el-upload--text {
            width: 40px;
            height: 40px;
            background: #081c30;
            border: 1px solid #57708d;
            border-radius: 3px;
            position: relative;
            i {
              font-size: 16px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          &.disabled {
            .el-upload.el-upload--text {
              width: auto;
              height: auto;
            }
          }
        }
        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          .avatar-uploader-icon {
            font-size: 16px;
            color: #8c939d;
            width: 350px;
            height: 30px;
            line-height: 30px;
            text-align: center;
          }
          .avatar {
            width: 350px;
            height: auto;
            display: block;
          }

          .title {
            font-size: 18px;
            margin-bottom: 20px;
          }
          .img__con {
            .el-button {
              width: 100%;
              margin: 10px 0 20px 0;
            }
          }
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .person-list {
          display: flex;
          flex-wrap: wrap;
          .person-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 4px 4px 0;
            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
            }
          }
        }
        .mean-item-div {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          .mean-items {
            height: 38px;
            background: #081c30;
            border-radius: 3px;
            padding: 0 10px;
            margin-right: 8px;
            margin-bottom: 19px;
            line-height: 38px;
            text-align: center;
            font-size: 14px;
            color: #3e90e5;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-date-editor--datetimerange.el-input,
      /deep/.el-date-editor--datetimerange.el-input__inner {
        width: 353px;
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border-color: #385576;
      }

      /deep/.el-date-editor .el-range-input {
        background-color: #081c30;
        color: #57708d;
        width: 44%;
      }

      /deep/.el-date-editor .el-range-separator {
        color: #fff;
      }

      /deep/.type .el-input__inner {
        width: 136px;
      }

      /deep/.type .el-select {
        width: 136px;
        margin-right: 26px;
      }

      /deep/.type .el-form-item__content {
        display: flex;
      }

      /deep/.type .el-select:nth-of-type(2) {
        margin-right: 0px;
      }

      /deep/.type-col.el-form-item.type {
        display: flex;
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      /deep/.detail-form,
      /deep/.complete-form {
        .el-form-item__content {
          p {
            color: #fff;
          }
        }
      }

      /deep/.detail-form {
        .el-form-item {
          margin-bottom: 15px;
        }

        .el-form-item__label,
        .el-form-item__content {
          line-height: 30px;
        }
      }
    }
  }
  .my-popover-tips {
    color: #f56c6c;
  }
  /deep/.my-time_value {
    display: flex;
    .el-form-item__content {
      margin-left: 0 !important;
      .el-input {
        width: 220px;
        margin-right: 20px;
      }
    }
  }
  /deep/.short-input {
    width: 217px;
  }
  .units {
    margin-left: 20px;
  }
    /deep/.detail-list-dialog {
    width: 80%;
    background-color: transparent;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      width: 100%;
      padding: 0;
      .detail-box {
        // width: 1467px;
        width: 100%;
        height: 837px;
        background: url(../assets/image/task/detail_pop_bg.png) no-repeat center
          center;
        background-size: contain;
        padding: 0 40px 0;

        .detail-dialog-title {
          width: 100%;
          height: 140px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-bottom: 20px;
          span {
            font-size: 28px;
            font-weight: bold;
            color: transparent;
            background: linear-gradient(0deg, #03f4fd 0%, #fff 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            letter-spacing: 2px;
          }
        }
        .list-main {
          width: 100%;
          height: 600px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 2px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #73b9f5;
          }
        }
      }
    }
  }
}
</style>
